import React, { Component } from "react";
import './QuizRadio.css';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from "uuid"

import { Consumer } from "../Context";


export default class QuizRadio extends Component {


    constructor(props) {

        super(props);
        console.log('====================================');
        console.log(`Props:${props}`);
        console.dir(props)
        console.log('====================================');
        this.radioButtonValue = ''
        this.helperText = ''
        this.error = false
        this.id = ''
        this.Course = ''
        this.subject = ''
        this.subTopic = ''
        this.qustionNumber = ''
        this.qustion = ''
        this.answers = ''
        this.userAnswerIndex = null
        this.correctAnswerIndex = null
        this.points = 0
        this.difficulty = ''
        this.qustionType = ''
        this.image = ''


        this.state = { id:props.id, radioButtonValue: props.radioButtonValue, helperText: props.helperText, error: props.error, id: '', Course: '', subject: '', subTopic: '', qustion: '', answers: [], checked: false, userAnswerIndex: null, points: 0, difficulty: props.difficulty, qustionType: '', qustionNumber: props.qustionNumber, correctAnswerIndex: props.correctAnswerIndex, radioButtonColor: 'default', textColor: 'black' };

    }


    setValue = (value) => this.setState({ radioButtonValue: value })
    setHelperText = (helperText) => this.setState({ helperText: helperText })
    setError = (error) => this.setState({ error: error })
    setColor = (color) => this.setState({ radioButtonColor: color })
    setTextColor = (color) => this.setState({ textColor: color })
    setDifficulty = (difficulty) => this.setState({difficulty:difficulty})
    setQustionObject = (radioButtonValue, helperText, error, id, Course, subject, subTopic, qustionNumber, qustion, answers, userAnswerIndex, correctAnswerIndex, points, difficulty, qustionType, image) => {

        this.setState({
            radioButtonValue: radioButtonValue, helperText: helperText, error: error, id: id, Course: Course, subject: subject, subTopic: subTopic, qustionNumber: qustionNumber, qustion: qustion, answers: answers, userAnswerIndex: userAnswerIndex, correctAnswerIndex: correctAnswerIndex, points: points, difficulty: difficulty, qustionType: qustionType, image: image
        })
        this.props.setQustionPoints(radioButtonValue, helperText, error, this.id, this.Course, this.subject, this.subTopic, this.qustionNumber, this.qustion, this.answers, userAnswerIndex, this.correctAnswerIndex, points, this.difficulty, this.qustionType, this.image)
        // console.log(this.state);
    }
    handleRadioChange = (event) => {


        let radioButtonValue = event.target.value
        let helperText = ''
        let error = false
        // event.target.setAttribute("checked", "checked")
        // console.log(event.target); 
        this.setState({ radioButtonValue: radioButtonValue, helperText: helperText, error: error });


    };

    handleSubmit = (event) => {

        event.preventDefault();
        // console.log(this.props.setQustionPoints(6,'Networking', this.props.subTopic, this.props.qustionNumber,10));
        let userAnswerIndex = parseInt(this.state.radioButtonValue)

        let radioButtons = document.querySelectorAll('.radio-button')
        // console.log(radioButtons);
        radioButtons.forEach(element => {
            // console.log(element.firstChild.checked);
        });
        // radioButtons.map( i => {
        //     console.log(i);
        // })

        if (userAnswerIndex === parseInt(this.state.correctAnswerIndex)) {

            this.setHelperText('איזה 👑 , המשך כך');
            this.setError(false);
            this.setColor('success')
            this.setTextColor('green')
            this.points = 10
            this.setDifficulty(this.difficulty)
            this.setQustionObject(userAnswerIndex, 'איזה 👑 , המשך כך', false, this.id, this.Course, this.subject, this.subTopic, this.qustionNumber, this.qustion, this.answers, userAnswerIndex, this.correctAnswerIndex, 10, this.difficulty, this.qustionType, this.image)

            // this.props.setQustionPoints(this.id, this.Course, this.subject, this.subTopic, this.qustionNumber, this.qustion, this.answers, userAnswerIndex, this.correctAnswerIndex, 10, this.difficulty, this.qustionType, this.image)

        } else {
            this.setHelperText('😮 מצטער , אבל טעית, לא נורא אל תתייאש  💪');
            this.setError(true);
            this.setColor('error')
            this.setTextColor('red')
            this.points = 0
            this.setDifficulty(this.difficulty)
            this.setQustionObject(userAnswerIndex, '😮 מצטער , אבל טעית, לא נורא אל תתייאש  💪', true, this.id, this.Course, this.subject, this.subTopic, this.qustionNumber, this.qustion, this.answers, userAnswerIndex, this.correctAnswerIndex, 0, this.difficulty, this.qustionType, this.image)

            this.props.setQustionPoints(this.id, this.Course, this.subject, this.subTopic, this.qustionNumber, this.qustion, this.answers, userAnswerIndex, this.correctAnswerIndex, 0, this.difficulty, this.qustionType, this.image)
        }

    };
    render() {
        const { radioButtonValue, helperText, error, radioButtonColor, textColor } = this.state;
        return (
            <Consumer>
                {
                    c => {

                        this.radioButtonValue = c.radioButtonValue
                        this.helperText = c.helperText
                        this.error = c.error
                        this.id = c.id
                        this.Course = c.Course
                        this.subject = c.subject
                        this.subTopic = c.subTopic
                        this.qustionNumber = c.qustionNumber
                        this.qustion = c.qustions[this.qustionNumber].qustion
                        this.answers = c.qustions[this.qustionNumber].answers
                        this.userAnswerIndex = c.qustions[this.qustionNumber].userAnswerIndex
                        this.correctAnswerIndex = c.qustions[this.qustionNumber].correctAnswerIndex

                        this.points = c.points
                        this.difficulty = c.difficulty
                        this.qustionType = "radio"
                        this.image = c.qustions[this.qustionNumber].image

                    
                        return (
                            <div className="QuizRadio-container" key={uuidv4()}>

                                <p className="radio-qustion">  {(c.qustions.length !== 0) ? this.qustion : ''} </p>
                                {/* <img className="quiz-image" src={image} /> */}
                                {
                                    <form onSubmit={this.handleSubmit} className="radio-form" >
                                        <FormControl sx={{ m: 3 }} error={error} variant="standard">
                                            <FormLabel id={`radio-qustion-${this.qustionNumber}`}>ניקוד: {c.qustions[this.qustionNumber].points}</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-error-radios"
                                                name="quiz"
                                                value={radioButtonValue}
                                                onChange={this.handleRadioChange}
                                            >
                                                {
                                                    this.answers.map((item, index) =>
                                                        <FormControlLabel key={uuidv4()} value={index} control={
                                                            <Radio color={radioButtonColor} className="radio-button"  />} label={item} />
                                                    )
                                                }

                                            </RadioGroup>
                                            <FormHelperText style={{ color: textColor }}>{helperText}</FormHelperText>
                                            <Button sx={{ mt: 1, mr: 1 }} type="button" variant="outlined" onClick={() => {
                                                c.actions.setQustionPoints(radioButtonValue,helperText,this.error, this.id, this.Course, this.subject, this.subTopic, this.qustionNumber, this.qustion, this.answers, this.userAnswerIndex, this.correctAnswerIndex, 10, "novice", this.qustionType, this.image)
                                            }}>
                                                Check Answer
                                            </Button>
                                        </FormControl>
                                    </form>
                                }
                            </div>
                        )
                    }
                }
            </Consumer>
        )



    }


}