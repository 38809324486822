import React, { Component } from 'react';
import './TestInstructionsNetworking.css';
import companyLogo from './../../../assets/Images/quiz.png';
import { Consumer } from "../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare, faPlay } from '@fortawesome/free-solid-svg-icons'
import Clock from '../../Clock/Clock';

export default class TestInstructionsNetworking extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {
                      
                        return (
                            <div className="paper TestInstructionsNetworking test" >
                                <h4 style={{ fontSize: '17pt' }}>Test contest</h4>

                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>הסבר</b>
                                </p>

                                <p>
                                    <b style={{ fontSize: '17pt' }}></b>
                                    <span style={{ fontSize: '17pt' }}>
                                        שלום, מבחן זה בא לשקף את רמת השליטה שלך בתקשורת.
                                    </span>

                                </p>

                                <p className='c9 list-items'>
                                    <b style={{ fontSize: '17pt' }}>דגשים</b>
                                    <span style={{ fontSize: '17pt' }}>עלייך להתחבר לחשבון הגוגל שלך</span>
                                    <span style={{ fontSize: '17pt' }}>המבחן מוגבל לזמן של שעה.</span>
                                    <span style={{ fontSize: '17pt' }}>לחיצה על כפתור התחל תפעיל טיימר</span>
                                    <span style={{ fontSize: '17pt' }}>המנצח הוא מי שיענה בזמן הכי מהיר על מספר השאלות הרב ביותר</span>
                                </p>

                                <Button variant="contained" color="success" onClick={(e) => {
                                  // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 28
                                    let completedIndex = 0
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let id = 6
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    // the last argument is 0, so when ypu build a quiz the first correct answer index is 0 always
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex,subjectsAndSubTopicsCompletedIndex,'radio',c.qustionNumber, 0, id)
                                    
                                }}>Start<Clock /><FontAwesomeIcon icon={faPlay} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>


                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
